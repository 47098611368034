/* 通用卡片样式 */
.card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
  width: 240px;
  box-sizing: border-box; /* 确保padding和border在width内 */
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(92, 84, 78, 0.3));
}

.class-daily {
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(200, 16, 46, 0.2));
}
.class-decorate {
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(205, 173, 132, 0.5));
}
.class-server {
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(15, 77, 146, 0.5));
}
.class-house {
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0, 133, 67, 0.5));
}

.icon-layer {
  position: relative;
  padding: 16px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size:  80px 80px;
}

.icon-weilaicheng1 {
  background-image: url("../img/weilaicheng1.svg");
}

.icon-wuyang {
  background-image: url("../img/wuyang.svg");
}

.icon-oldstreet {
  background-image: url("../img/oldstreet.svg");
}

/* 卡片容器样式 */

/* 标题样式 */
.card-title {
  font-size: 1.3em;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 标签容器样式 */
.card-tags {
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

/* 单个标签样式 */
.card-tag {
  display: flex;
  background-color: lightskyblue;
  color: white;
  border-radius: 8px;
  padding: 2px 8px;
  margin-right: 4px;
  font-size: 0.7em;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

/* 发布者和电话样式 */
.card .card-author {
  width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

/* 正文样式（仅详细版显示） */
.card-content {
  font-size: 1.1em;
  margin: 12px 0;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 发布时间样式 */
.card-date {
  font-size: 0.8em;
  color: #888;
}

.hor_line {
  position: relative;
  display: flex;
  gap: 20px;
  justify-items: center;
}

.food-icon {
  position: absolute;
  top: 45px;
  right: 30px;
}

@media screen and (max-width: 520px) {
  .card {
    width: 45%;
  }
  .hor_line {
    gap: 0px;
    flex-direction: column;
  }
  .card .card-author {
    width: 100%;
  }
}
