.sms-login-form {
}

.sms-login-form .auth_line{
  display: flex;
  flex-direction: row;
  height: 24px;
  gap: 8px;
  align-items: center;
}


