.tab-header {
    flex-direction: row;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}

.tab {
    padding: 10px 20px;
}
.tab.active {
    border-bottom: 2px solid #007bff;
    font-weight: bold;
}
