.auth-line {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.info_container button{
  font-size: 0.8em;
  min-width: 80px;
}

.info_container button:disabled{
    opacity: 0.8;
  }
  

.profile_container input {
  height: 24px;
  width: 120px;
}

.profile_container .modify {
  margin-left: 6px;
  font-size: 0.8em;
  color: cornflowerblue;
  cursor: pointer;
}
  
.profile_container  .modify:hover {
    color: rgb(25, 96, 228);
  }