.amap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}

.map-item {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: absolute;
  right: 10px;
  bottom: 20px;
  color:grey;
  z-index: 9999;
  cursor: pointer;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
}

.re-focus {
  bottom: 60px;
}

.open-location {
  width: 120px;
}

.re-location {
  bottom: 20px;
}

/* .container::before {
  content: "";
  background-image: url('../img/background.jpg');
  background-size: contain; 
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: 0.3;
  pointer-events: none; 
}  */
