/* src/PhotoWall.css */

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  /* height: 100vh; */
}

.dynamic-area {
  display: flex;
  margin-bottom: 50px;
  height: 30vw;
}

.image-section {
  flex: 1;
  width: 50%;
}

.image-section img {
  /* max-width: 50%; */
  height: auto;
  border-radius: 10px;
  position: relative;
  float: left;
  top: 20px;
  left: 0;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.image-section img.fade-in {
  opacity: 1;
  /* animation: fadeIn 1s; */
}

@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.text-section {
  top: 20px;
  float:right;
  width: 50%;
  padding: 20px;
  left: 20px;
  background-color: rgba(173,216,230,0.3);
  border-radius: 10px;
  position: relative;
}

.container h1 {
  margin-bottom: 20px;
}

.container p {
  margin-bottom: 10px;
  line-height: 1.6;
  text-indent: 2em;
  white-space: pre-wrap;
}

.text-section .slide-in {
  opacity: 0;
  transform: translateY(10px);
  animation: slideIn 0.8s forwards;
}

.tip-box {
  margin: 20px 0;
  display: flex;
  gap: 20px;
  overflow-x: auto; 
}

.tip {
  /* background: url('path_to_your_paper_image.svg') no-repeat center; */
  /* background-size: cover; */
  background-color: antiquewhite;
  border: 1px solid darkolivegreen;
  border-radius: 8px;
  padding: 16px;
  line-height: 1.6;
  min-width: 280px;
  /* margin-right: -40px;  */
  
}

.tip p {
  text-decoration: underline;
  text-decoration-color:dimgrey ;
  text-underline-offset: 4px;
  word-break: break-all; /* 强制在任何字符处换行 */
  overflow-wrap: break-word; /* 优先在单词边界处换行 */
}



#map {
  width: 300px;
  height: 180px;
}


@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 520px) {
  .dynamic-area {
    height: 70vw;
  }

  .image-section {
    width: 100%;
  }

  .text-section {
    width: 90%;
    top: 40px;
    position: absolute;
    background-color: rgba(173,216,230,0.7);
    color:aliceblue;
  }
}

