body {
    background-color: lightgray;
}
.Home {
    position: relative;
}

.Welcome {
    width: 100%;
    height: 32vh;
    background-image: url('img/jiangkoushuizhen_2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nav_bar {
    background-color: rgba(211, 221, 211, 0.3);
    padding: 10px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-self: start;
}

.nav_item {
    color: rgba(3, 22, 49, 0.5);
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    font-size: 18px;
    text-decoration: none
}

.nav_item.active, .nav_item.active:hover {
    transform: scale(1.2); 
    color: rgba(3, 22, 49, 0.9);
}

.nav_item:hover {
    transform: scale(1.1); 
    background-color: rgba(3, 22, 49, 0.1);
}

.right-tab {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.contact {
  font-size: 1.2em;
  color: lightslategrey;
}

.contact:hover {
  font-size: 1.2em;
  color: darkslategray;
}

.email {
  position: absolute;
  display: none;
  z-index: 10;
  font-size: 0.8em;
  background-color: #f8f8f8;
  border: #666 1px solid;
  border-radius: 8px;
  padding: 4px 8px;
  right: 0px;
  white-space: nowrap;
}

.contact:hover .email {
  display: block;
}

.load {
    text-decoration: none;
    color: darkslategray;
}

.footer {
  background-color: #f8f8f8;
  text-align: center;
  padding: 10px 0;
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  top: 50px;
  clear: both;
}

.record-number {
  font-size: 14px;
  color: #666;
}

.record-number a {
  color: #666;
  text-decoration: none;
}

.record-number a:hover {
  text-decoration: underline;
}
