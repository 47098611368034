.ad-space {
  position: relative;
}
.selection {
    border: 1px rgb(174, 152, 210, 0.6) solid;
    padding: 4px 12px;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
    color: darkslategrey;
    align-items: center;
}

.toolbar{
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 20px;
    right: 20px; /* 调整工具栏距离右侧边界的距离 */
    background-color: #fff; /* 工具栏背景颜色 */
    border: 1px solid #ccc; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 阴影效果 */
    z-index: 1000; /* 可选：控制工具栏的堆叠顺序 */
    border-radius: 8px;
}

.card-container {
  display: flex;
  flex-wrap: wrap; /* 允许卡片换行 */
  gap: 16px; /* 卡片之间的间隙 */
  margin: 0 8px; /* 负margin用于抵消gap对容器的影响 */
}

.ads_container {
    display: flex;
    flex-wrap: wrap; /* 允许卡片换行 */
    gap: 16px; 
    margin: 0 16px;
}

.classify-item {
  cursor: pointer;
  padding: 0px 4px;
  border-radius: 8px;
}

.classify-item.selected {
  border: 1px solid lightblue;
}

.ad_item{
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 32px;
  margin: 6px;
  align-items: center;
  justify-content: center;
  background-color: antiquewhite;
}

.ad_add {
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 32px;
  margin: 6px;
  align-items: center;
  justify-content: center;
  color:cornflowerblue;
  font-size: 2.8em;
}

.card-author,
.card-phone {
  font-size: 0.9em;
  margin-bottom: 4px;
}

@media screen and (max-width: 520px) {
  .toolbar {
    flex-direction: row;
    bottom: 20px;
    top: unset;
    right: 20px;
  }
}