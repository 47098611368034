.profile_container{
  display: flex;
  padding: 100px 24px;
  gap: 16px;
}
.profile_panel{
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  width: 280px;
  min-width: 280px;
  height: 80vh;
  padding: 16px;
  gap: 16px;
  background-color: antiquewhite;
  border-radius: 8px;
}

.right_panel{
  width: 100%;
}

.info_container {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 60px 1fr; */
  gap: 10px;
  padding: 10px;
}

.ads_panel{
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  min-width: 500;
  padding-bottom: 50px;
}

.profile_container text {
  font-size: 0.9em;
}
.profile_container .msg_del {
  right: 170px;
}

.cue-word {
  width: 60px;
  text-align: end;
  color: darkslategray;
  white-space: nowrap;
}

.profile-line {
  display: flex;
  gap: 4px;
  align-items: center;
}

@media screen and (max-width: 520px) {
  .profile_panel{
    height: auto;
    width: 100%;
  }
  .profile_container {
    flex-direction: column;
  }
}