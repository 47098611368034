/* 覆盖整个页面的半透明背景 */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* 弹框内容样式 */
  .modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    position: relative;
    width: 80%;
    display: inline-flex;
    border: 1px solid rgba(0, 0, 0, 0.3);
    max-width: 640px;
    max-height: 80vh;
    overflow-y: auto;
    opacity: 1;
  }

  .modal-close {
    position: absolute;
    top: 0px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }