.login_view {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e0ffff;
}
.login_container {
  width: 300px;
  height: 400px;
  border-radius: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  background-color:  white;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  top: 20px;
  justify-content:center;
  transition: transform 0.5s ease;
  /* gap: 10px; */
}

.form {
  width: 230px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  /* align-items: center; */
  gap: 10px; 
}
  
.link {
  right: 0;
  color: '#007bff';
  text-decoration: none;
  font-size: 14px;
  right: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.switch {
  position: absolute;
  color: '#007bff';
  font-size: 14px;
  text-decoration: none;
  bottom: 20px;
  left: 20px;
}

.login_view input {
  width: 100%;
  color: darkslategray;
  text-decoration: none;
  font-size: 14px;
}


.login_view button {
  padding: 4px 6px;
  background-color: lightskyblue;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}


.back {
  position: fixed;
  color: darkcyan;
  top: 20px;
  left: 20px;
  font-size: 3em;
}

.visible_icon {
  position: absolute;
  transform: translateY(10%);
  right: 45px;
}

.login_view .label{
  position: absolute;
  font-size: 14px;
  transform: translateY(15%) translateX(15%)
}

.login_view .tel{
    text-indent: 32px;
}

.code-btn {
  white-space: nowrap;
  width: 180px;
}

.code-btn:disabled {
  opacity: 0.7;
}
