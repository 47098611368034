.ad_release {
  padding: 24px 12px 12px 12px;
  font-family: 'Arial', sans-serif;
  color: #333;
  box-sizing: border-box; 
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ad_release input {
  width: 100%;
  color: darkslategray;
  text-decoration: none;
  font-size: 14px;
}

.ad_release .content {
  width: 100%;
  height: 200px;
  display: block
}

.ad_release button {
    padding: 4px 6px;
    background-color: lightskyblue;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

.tag-list {
  display: flex;
}
.card_tag {
  /* display: inline-block; */
  display: flex;
  background-color: lightskyblue;
  color: white;
  border-radius: 8px;
  padding: 2px 8px;
  margin-right: 4px;
  font-size: 0.7em;
  align-items: center;
  gap: 6px;
}

.district_area {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.district {
  font-size: 0.9em;
  cursor: pointer;
}
.district_active {
  font-size: 0.9em;
  border: 1px solid cornflowerblue;
  padding: 2px 6px;
  border-radius: 6px;
}


.card_tag:hover .remove_tag{
  display: block;
}

.remove_tag {
  cursor: pointer;
  color: red;
  display: none;
  font-size: 0.8em;
  scale: 1.8;
}

.select_area {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 6px;
  font-size: 14px;
}

.ad_release .read_only {
  border: 1px solid transparent;
}

.button_group {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
}
.edit_button {
  flex-grow: 1;
}

.title {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 8px;
  max-height: 20vh;
  overflow: auto;
  border-bottom: lightblue 1px solid;
  padding-bottom: 4px;
}