.ad_content {
  border-radius: 8px;
  padding: 16px;
  /* margin: 16px 0; */
  box-shadow: 0 2px 4px rgba(255, 249, 249, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
  box-sizing: border-box; /* 确保padding和border在width内 */
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 8px;
  max-height: 20vh;
  overflow: auto;
  border-bottom: lightblue 1px solid;
  padding-bottom: 4px;
}

.ad_content input {
    width: 100%;
    color: darkslategray;
    text-decoration: none;
    font-size: 14px;
  }

.ad_content button {
    padding: 4px 6px;
    background-color: lightskyblue;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}


.ad_content .hor_line {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-items: center;
}
.card_content {
  max-height: 50vh;
  overflow: auto;
  border: 1px lightblue solid;
  padding: 8px;
  border-radius: 8px;
  white-space: pre-wrap;
}