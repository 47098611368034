.msg_container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 200px;
}

.msg_box {
  margin: 12px;
  padding: 6px 6px 30px 6px;
  border-bottom: 1.5px solid lightgray;
}

.msg_container textarea {
    margin: 12px;
}

.msg_publisher {
  position: absolute;
  color: darkslategray;
  font-size: 0.8em;
}
.msg_publish_time{
  position: absolute;
  right: 30px;
  color: darkslategray;
  font-size: 0.8em;
}

.msg_del {
  position: absolute;
  right: 160px;
  font-size: 0.9em;
  color: darkslategray;
}

.msg_del:hover{
  color: black;
}

.message_input {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  justify-content: end;
  align-items: baseline;
}

.operater {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.deliver {
  width: 36px;
  height: 36px;
  border-radius: 24px;
  font-size: 1.2em;
}

.message_text {
  flex-grow: 1;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  padding: 0;
}

.context {
  white-space: pre-wrap;
}


.msg_container .content {
  height: 100px;
  width: 100%;
}

.msg_container .at_top {
  right: 20px;
}

@media screen and (max-width: 520px) {
  .message_input {
    flex-direction: column;
    align-items: end;
  }
  .message_text {
    width: 100%;
  }
  .operater {
    flex-direction: row;
  }
}