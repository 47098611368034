.regiter-form {
  
}
.regiest_code {
  display: flex;
  flex-direction: row;
  height: 24px;
  gap: 8px;
  /* margin-bottom: 10px; */
  align-items: center;
}

.login_view .input_code {
  flex-grow: 1;
}

.regiter-form button {
  white-space: nowrap;
}
