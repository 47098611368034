.marquee {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%; /* 走马灯显示区域的宽度 */
  overflow: hidden; /* 隐藏超出部分 */
  white-space: nowrap; /* 防止文字换行 */
  box-sizing: border-box;
  border: 1px solid hsl(0, 0%, 87%); /* 可选：添加边框 */
  color: wheat;
  background-color: dimgrey;
  z-index: 9;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%; /* 初始位置 */
  animation: marquee 20s linear infinite; /* 走马灯动画 */
}

.marquee-content:hover {
  animation-play-state: paused; /* 悬停时暂停动画 */
}

.marquee-close {
  position: absolute;
  top: -13px;
  right: 0;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  color: white;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
