.error_page{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: lightcyan;
}

.error_page .content{
  margin: 100px 0;
  padding: 30px 100px;
  background-color: lightskyblue;
}
